import React from "react"
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'

const ImprintPage = ({ location, ...restProps }) => {
  return (
    <Layout location={location}>
      <Helmet title={`Threefields Media - Imprint`} />
      <h1 className="mb-6">Imprint</h1>
      <h2 className="mb-4">Information according to § 5 TMG</h2> 
      <p>
        Stefanos Rafail Trialonis<br />
        Pfenningsbusch 30<br />
        22081 Hamburg<br />
      </p>
      <br />
      <h2 className="mb-4">Contact</h2>
      <p>
        <strong>Phone:</strong>
        <a href="tel:+4915161588343"> +49 (0) 1516 1588343</a><br />
        <strong>E-Mail:</strong>
        <a href="mailto:stefanos@threefields.media"> stefanos@threefields.media</a>
      </p>
      <br />
      <p>Liable according to § 55 RStV: Stefanos Rafail Trialonis (address as above)</p>
      <br />
      <h2 className="mb-4">Disclaimer</h2>
      <p>
        Despite careful control of the content, we assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content.
      </p>
    </Layout>
  )
}

export default ImprintPage
